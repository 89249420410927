<template>
  <div id="app">
    <Navbar />

    <b-container fluid class="py-5 main-container">
      <transition
        name="fade"
        mode="out-in"
      >
        <router-view />
      </transition>
    </b-container>

    <Footer />
    <!-- <img alt="Vue logo" src="./assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/> -->
  </div>
</template>

<script>
import Navbar from './components/Navbar'
import Footer from './components/Footer'
// import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    Navbar,
    Footer,
    // HelloWorld
  }
}
</script>

<style lang="scss" scoped>
.main-container {
  min-height: 100vh;
  height: auto;
  background: url("/ressources/background-cnhj.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  background-attachment: fixed;
}
</style>