<template>
  <b-container fluid>
    <b-row class="my-3">
      <b-col>
          <p style="text-align: right;">
            &copy; CNCJ 2021 – SECURACT : Prise de consentement - 
            <router-link :to="{ name: 'legalTerms'}">Mentions légales</router-link>
          </p>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "Footer",
  components: {},
};
</script>