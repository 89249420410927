<template>
  <b-container fluid class="navbar-wrapper">
    <b-row>
      <b-navbar class="navbar">
        <b-navbar-brand href="/">
          <img
            src="/ressources/logo-cnhj.png"
            width="150"
          />
          Déclaration de consentement à la signification électronique des actes
        </b-navbar-brand>
      </b-navbar>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "Navbar",
  components: {},
};
</script>

<style lang="scss" scoped>
.navbar-wrapper {
  background-color: #F0F0F0 !important;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}
</style>